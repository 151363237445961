import React, { createContext, useContext, useState, useEffect } from 'react';

const CreditContext = createContext();

export const useCredit = () => {
  return useContext(CreditContext);
};

export const CreditProvider = ({ children }) => {
  const [credit, setCredit] = useState(localStorage.getItem('userCredit') || 0);

  useEffect(() => {
    const storedCredit = localStorage.getItem('userCredit');
    if (storedCredit) {
      setCredit(storedCredit);
    }
  }, []);

  const updateCredit = (newCredit) => {
    setCredit(newCredit);
    localStorage.setItem('userCredit', newCredit);
  };

  return (
    <CreditContext.Provider value={{ credit, updateCredit }}>
      {children}
    </CreditContext.Provider>
  );
};

export default CreditContext;