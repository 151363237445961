

import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Container } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AiOutlineMail } from "react-icons/ai";
import { IoPhonePortraitSharp } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdOutlineHeadphones } from "react-icons/md";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FaShieldAlt } from "react-icons/fa";
import Login from "./Login";

export default function ForgotPsw() {
  const [mobile, setMobile] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [new_password, setPassword] = useState("");
  // const [new_password, setNewPassword] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});


  useEffect(() => {
    let timer;
    if (isButtonDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled]);

  const handleSendOTP = async () => {

    if (!mobile || !/^\d{10}$/.test(mobile)) {
      setErrors({ ...errors, mobile: "Enter a valid 10-digit phone number." });
      return;
    }
    setIsButtonDisabled(true);
    setCountdown(25);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mobile })
      });
      if (response.ok) {
        const data = await response.json();
        if (data.success === "1") {
          setOtp(data.otp);
          setOtpSent(true);
        } else {
          setError(data.message);
        }
      } else {
        setError("Failed to send OTP. Please try again later.");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const handleResetPsw = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mobile, otp, new_password })
        });

        const data = await response.json();
        if (data.message === "Password reset successfully") {
            Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            });
            window.location.href = "/";
        } else if (data.error === "Invalid OTP or mobile number") {
            Swal.fire({
                title: 'Invalid OTP or mobile number',
                text: 'Please try with a different OTP or mobile number.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            // Handle other potential errors returned by the API
            Swal.fire({
                title: 'Error',
                text: data.error || 'An unexpected error occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    } catch (error) {
        console.error('Error resetting password:', error);
        setError("An unexpected error occurred. Please try again later.");
    }
};

  

  

  return (
    <section className="Login_section">
      <div className="Login">
        <Container>
          <div className="d-flex justify-content-between align-items-center py-2">
            <Link to="/">
            <div className="arrow_login">
              <IoIosArrowBack className="text-white" />
            </div>
            </Link>
            <div className="headinglogin">
              <h5 className="text-white mb-0">Forgot Password</h5>
            </div>
            <div className="language text-white">Hindi</div>
          </div>
          <div className="text-start Login_heading text-white">
            <h1 className="">Forgot Password</h1>
            <p className="mb-0">
              Please retrive/change your password through your mobile phone number or email
            </p>
            {/* <p className="mb-0">
              If you forget your password, please contact customer service
            </p> */}
          </div>
        </Container>
      </div>
      <Container>
        <div className="login_method">
          <Tabs>
            <TabList>
              <Tab>
                <div className="width-tab">
                  <div className="icon_login">
                    <IoPhonePortraitSharp />
                  </div>
                  <h6 className="mb-0">phone reset</h6>
                </div>
              </Tab>
              <Tab>
                <div className="width-tab">
                  <div className="icon_login">
                    <AiOutlineMail />
                  </div>
                  <h6 className="mb-0"> Email reset</h6>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="Login_form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <IoPhonePortraitSharp />
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setMobile(e.target.value)}
                      type="tel"
                      placeholder="Enter Phone Number"
                      className="input_login_radius_custum"
                    />
                       <Button 
                        className="send_button1" 
                        onClick={handleSendOTP} 
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled ? `Resend (${countdown}s)` : (otpSent ? "Resend" : "Send")}
                      </Button>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                     A new Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="A new password"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                     Confirm new Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Confirm new password"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaShieldAlt />
                      Verification Code
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Control
                      onChange={(e)=>setOtp(e.target.value)}
                        type="text"
                        placeholder="Please enter the confirmation code"
                        className="input_login_radius_custum"
                      />
                      {/* <Button className="send_button" onClick={handleSendOTP}>
                   {otpSent ? "Resend" : "Send"}
                 </Button> */}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check type="checkbox" label="I have read and agreed" />
                  </Form.Group>
          
                  <div className="register_btn">
                    <Link className="register" to="/Register">
                      <button onClick={handleResetPsw}>Reset Password</button>
                    </Link>
                  </div>
                </Form>
    
              </div>
            </TabPanel>
            <TabPanel>
              <div className="Login_form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <AiOutlineMail />
                      Mail
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                     A new Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="A new password"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                     Confirm new Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Confirm new password"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaShieldAlt />
                      Verification Code
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Control
                      onChange={(e)=>setOtp(e.target.value)}
                        type="text"
                        placeholder="Please enter the confirmation code"
                        className="input_login_radius_custum"
                      />
                      <Button className="send_button">
                   {otpSent ? "Resend" : "Send"}
                 </Button>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check type="checkbox" label="I have read and agreed" />
                  </Form.Group>
          
                  <div className="register_btn">
                    <Link className="register" to="/Register">
                      <button>Reset Password</button>
                    </Link>
                  </div>

                </Form>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    </section>
  );
}
