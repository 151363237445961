import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { HiSpeakerphone } from "react-icons/hi";
import axios from 'axios';
import { MdOutlineSupportAgent } from "react-icons/md";
import { SiNeteasecloudmusic } from "react-icons/si";

export default function Deposit() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}color-Game-MessageList?pageNo=1&limit=10`);
        if (response.data && response.data.data && response.data.data.list) {
          setMessages(response.data.data.list);
        } else {
          console.error('Unexpected API response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  return (
    <section className='withdraw'>
       <div className="topheader p-2 bg-color-all d-flex justify-content-between align-items-center">
          <Link to="/Wingo" className="text-white text-2xl">
            <MdOutlineKeyboardArrowLeft />
          </Link>
          <h4 className="mb-0 text-white">Notification</h4>
          <div className="d-flex">
            <Link to="/home" className="w-5 text-white">
              <MdOutlineSupportAgent />
            </Link>
            <SiNeteasecloudmusic className="w-5 text-white" />
          </div>
        </div>
      {/* <div className='topheader p-2 bg-gray d-flex justify-content-center align-items-center'>
        <Link to="/Wingo" className='text-dark text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
        <h6 className="mb-0 text-dark">Notification</h6>
      </div> */}
      <Container fluid>
        {loading ? (
          <div>Loading...</div>
        ) : (
          messages.length > 0 ? (
            messages.map((message, index) => (
              <div key={message._id} className="card_custum rounded-sm mb-1">
                <div className="d-flex justify-content-center flex-col gap-2">
                  <Card border="0" style={{ width: 'auto' }}>
                    <Card.Body>
                      <div className="rounded-sm d-flex text-start ">
                        <span><HiSpeakerphone style={{ color: "orange", fontSize: "32px" }} /></span>
                        <div>
                        <h6 className="text-base">{message.title}</h6>
                        <p className="text-sm mb-0">
                          {message.siteMessage}
                        </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ))
          ) : (
            <div>No messages found.</div>
          )
        )}
      </Container>
    </section>
  );
}
