import React from 'react'
import { LiaTrophySolid } from "react-icons/lia";
import {Row,Col,Card} from 'react-bootstrap';
import profile from '../assets/images/profile.png'
import gameimage from '../assets/images/gameimage.png'
export default function Wininginfo() {
    return (
        <section>
            <Row>
                <Col xs={12}>
                    <div className="heading">
                        <div className="box">
                            <LiaTrophySolid className='trophy' />

                            <p>Winning information
                                <span className="design_heading"></span>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12}>
                    <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>1</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                                    <picture>
                                        <img src={gameimage} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user text-start">
                                        <p className="mb-0 ml-1 text-xs	"><b>Receive ₹9,240.00</b></p>
                                        <p className="mb-0 ml-1 text-xs	">Winning amount</p>
                                    </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>2</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                                    <picture>
                                        <img src={gameimage} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user text-start">
                                        <p className="mb-0 ml-1 text-xs	"><b>Receive ₹9,240.00</b></p>
                                        <p className="mb-0 ml-1 text-xs	">Winning amount</p>
                                    </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>3</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                                    <picture>
                                        <img src={gameimage} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user text-start">
                                        <p className="mb-0 ml-1 text-xs	"><b>Receive ₹9,240.00</b></p>
                                        <p className="mb-0 ml-1 text-xs	">Winning amount</p>
                                    </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>4</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                                    <picture>
                                        <img src={gameimage} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user text-start">
                                        <p className="mb-0 ml-1 text-xs	"><b>Receive ₹9,240.00</b></p>
                                        <p className="mb-0 ml-1 text-xs	">Winning amount</p>
                                    </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>5</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                                    <picture>
                                        <img src={gameimage} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user text-start">
                                        <p className="mb-0 ml-1 text-xs	"><b>Receive ₹9,240.00</b></p>
                                        <p className="mb-0 ml-1 text-xs	">Winning amount</p>
                                    </div>
                            </div>
                        </div>
                    </Card>
                </Col>

            </Row>
        </section>
    )
}
