import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { LuActivitySquare } from "react-icons/lu";
import { IoDiamond } from "react-icons/io5";
import { FaWallet } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";

export default function Footer() {
  const [activeLink, setActiveLink] = useState(1); // Defaulting Home link to be active

  const handleLinkClick = (linkNumber) => {
    setActiveLink(linkNumber);
  };

  return (
    <footer className="footer">
      <div className="d-flex justify-content-between gap_custum">
        <Link
          to="/home"
          className={`footerlink text-center ${activeLink === 1 ? "active" : ""}`}
          onClick={() => handleLinkClick(1)}
        >
          <div className="footericon d-flex justify-content-center">
            <IoMdHome     onClick={() => handleLinkClick(1)}/>
          </div>
          <span  onClick={() => handleLinkClick(1)}>Home</span>
        </Link>
        <Link
          to="/Activity"
          className={`footerlink text-center ${activeLink === 2 ? "active" : ""}`}
          onClick={() => handleLinkClick(2)}
        >
          <div className="footericon d-flex justify-content-center">
            <LuActivitySquare     onClick={() => handleLinkClick(2)}/>
          </div>
          <span     onClick={() => handleLinkClick(2)}> Activity </span>
        </Link>
        <Link
          to="/promotion"
          className={`footerlink  text-center ${activeLink === 3 ? "active" : ""}`}
          onClick={() => handleLinkClick(3)}
        >
          <div className="footericon promotion d-flex justify-content-center">
            <IoDiamond     onClick={() => handleLinkClick(3)}/>
          </div>
          <span     onClick={() => handleLinkClick(3)}> Promotion</span>
        </Link>
        <Link
          to="/wallet"
          className={`footerlink text-center ${activeLink === 4 ? "active" : ""}`}
          onClick={() => handleLinkClick(4)}
        >
          <div className="footericon d-flex justify-content-center">
            <FaWallet     onClick={() => handleLinkClick(4)}/>
          </div>
          <span     onClick={() => handleLinkClick(4)}> Wallet</span>
        </Link>
        <Link
          to="/Account"
          className={`footerlink ${activeLink === 5 ? "active" : ""}`}
          onClick={() => handleLinkClick(5)}
        >
          <div className="footericon d-flex justify-content-center">
            <MdAccountCircle     onClick={() => handleLinkClick(5)}/>
          </div>
          <span     onClick={() => handleLinkClick(5)}> Account</span>
        </Link>
      </div>
    </footer>
  );
}
