import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderimage from '../assets/images/sliderimage.jpg'
import poolball from '../assets/images/poolball.png'
import lottery from '../assets/images/lottery.png'
import { Container, Row, Col } from 'react-bootstrap'
import {Link} from 'react-router-dom';
import { AiFillSound } from "react-icons/ai";
import { FaGripfire } from "react-icons/fa";
import { TfiViewGrid } from "react-icons/tfi";
import Wininginfo from './Wininginfo';
import Todayearningchart from './Todayearningchart';

export default function Home() {
    var sliderhome = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="Homesec padding_all">
            <Container>
                <Slider {...sliderhome}>
                    <div>
                        <img src={sliderimage} className="img-fluid" />
                    </div>
                    <div>
                        <img src={sliderimage} className="img-fluid" />
                    </div>
                    <div>
                        <img src={sliderimage} className="img-fluid" />
                    </div>
                    {/* Add more slides here if needed */}
                </Slider>

                {/* marquee tag */}
                <div className="Marquee_Tag bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="h-25 w-28 sound_icon">
                            <AiFillSound />
                        </div>
                        <div className="Notice">
                            <p className="">Our customer service never send a link to the member,if you received a link from someone who pro-claimed as 91club customer service do not click the link to prevent being hack or lost data. Thank you</p>
                        </div>

                        <div className="details">
                            <button className="text-slate-900	 text-white d-flex"><FaGripfire />Details</button>
                        </div>
                    </div>

                </div>
                <Row>
                    <Col xs={3} sm={4}>
                        <div className="bg-red tab_grid">
                            <img src={lottery} />
                            Lottery
                        </div>
                    </Col>
                    <Col xs={9} sm={8}>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <Link className='d-flex justify-content-center border-orange-500 text-orange-500 align-items-center border-2 rounded-full p-2 no-underline'>
                          View All <TfiViewGrid className="ml-2" />

                        </Link>
                    </Col>
                </Row>
                <Wininginfo/>
                <Todayearningchart/>
            </Container>
        </div>
    );
}
