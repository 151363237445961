import React from 'react'
import Logo from '../assets/images/Logo.png'
import { Container, Row, Col } from 'react-bootstrap'
import { FaChevronDown } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { FaFilter } from "react-icons/fa";

import { Link } from 'react-router-dom'
export default function Header() {
  return (
    <section className="Header_section">
      <Container>
          <div className="d-flex justify-content-between align-items-center">
            <div></div>
          <div className="align-items-center">
          <div className="text-center position-relative left-3	 ">
            Agency
          </div>
        </div>
        <div className='d-flex'>
          <Link to="/" className=" text-white-700 header_icon">  <FaFilter className="hover:color-green:600  text-red-700" /></Link>
        </div>
          </div>
      </Container>
    </section>
  )
}
