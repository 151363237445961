import {React, useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col, Badge } from 'react-bootstrap';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import profile from '../assets/images/profile.png';
import { AiOutlineCopy } from "react-icons/ai";
import { FiRefreshCcw } from "react-icons/fi";
import { GiWallet } from "react-icons/gi";
import { TbWallet } from "react-icons/tb";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GrDocumentVerified } from "react-icons/gr";
import { FcDocument } from "react-icons/fc";
import { IoIosNotifications } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import { AiFillGift } from "react-icons/ai";
import { FcStatistics } from "react-icons/fc";
import { FaLanguage } from "react-icons/fa6";
import { FiSettings } from "react-icons/fi";
import { MdFeedback } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaBookOpen } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { MdCurrencyRupee } from "react-icons/md";
import axios from "axios";
import Swal from 'sweetalert2';

export default function Account() {

    
  const user_id = localStorage.getItem("user_id");
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const [images, setImage] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const credit = localStorage.getItem('userCredit');

  function handleLogout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to log out?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log out!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
      }
    });
  }

  useEffect(() => {
    if (!isLoading.current) {
      fetchGames();
    }
  }, []);
  const fetchGames = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}profile-list`;
  
      setLoading(true);
  
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };
  
      const response = await axios(config);

      const responseData = response.data.data;
      setUserData(responseData);
      const credit = responseData.credit;
      // alert(credit);
    localStorage.setItem('userCredit', credit);

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

    
    return (
        <section className="Account padding_all">
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/home" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Account</h4>
                <Link to="/" className='w-5'></Link>
            </div>
            <div className="account_details">
                <Container>
                    <div className="d-flex">
                        <div className="userprofile">
                            <img src={profile} alt="profile image" className="rounded-full h-16 w-16" />
                        </div>
                        <div className="userdetails d-flex flex-col	 gap-y-2">
                            <h3 className="mb-0">MEMBERNNGWFBMH</h3>
                            <p className="text-xs text-white bg-color-tag d-flex uid_number padding_custum_account justify-content-center align-items-center  rounded-full gap-2">
                                <span>UID</span>|<span>{user_id}</span>|<span><AiOutlineCopy /></span>
                            </p>
                            <p class="userinofologin mb-0"><span>Last login:&nbsp;</span><span>2024-04-24 16:41:24</span></p>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <Card className="position-relative totalbalance">
                    <Card.Title><p className="mb-0 text-start text-xs">Total Balance</p></Card.Title>
                    <Card.Subtitle className="totalbalance_border text-dark text-sm "><h3 className="d-flex mb-2 text-dark align-items-center text-sm gap-2"><MdCurrencyRupee className="text-sm" />{credit}<FiRefreshCcw /></h3></Card.Subtitle>
                    <Card.Body className="p-0">
                        <div className="d-flex justify-content-between gap-2">
                            <Link to="/wallet" className="text-white text-center">
                            <div className="walleticon_de">
                                <div className="icon_all">
                                    <GiWallet style={{ "--i": "#fcc999" }} />
                                </div>
                                Wallet
                            </div>
                            </Link>
                            <Link to="/deposit" className="text-white text-center">
                            <div className="walleticon_de">
                                <div className="icon_all">
                                    <TbWallet style={{ "--i": "#5ca6ff" }} />
                                </div>
                                Deposit
                            </div>
                            </Link>
                            <Link to="/Withdraw" className="text-white text-center">
                            <div className="walleticon_de">
                                <div className="icon_all">
                                    <IoDocumentTextOutline style={{ "--i": "#f95b5b" }} />
                                </div>
                                Withdraw
                            </div>
                            </Link>
                            {/* <div className="walleticon_de">
                                <div className="icon_all">
                                    <GrDocumentVerified style={{ "--i": "#fcc999" }} />
                                </div>
                                Vip
                            </div> */}
                        </div>
                    </Card.Body>
                </Card>
                <Row>
                    <Col xs={6}>
                        <Card className="padding_custum_history mt-2">
                            <div className="d-flex align-items-center game_history">
                                <div className="game_history_icon d-flex justify-content-start">
                                    <FcDocument />
                                </div>
                                <div className="text-start content_history">
                                    <h6 className="mb-0 capitalize">game History<span className="capitalize">My Game History</span></h6>

                                </div>


                            </div>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card className="padding_custum_history mt-2">
                            <div className="d-flex align-items-center game_history">
                                <div className="game_history_icon d-flex justify-content-start">
                                    <FcDocument />
                                </div>
                                <div className="text-start content_history">
                                    <h6 className="mb-0 capitalize">Transaction<span className="capitalize">My Transaction History</span></h6>

                                </div>


                            </div>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card className="padding_custum_history mt-2">
                       
                            <div className="d-flex align-items-center game_history">
                                <div className="game_history_icon d-flex justify-content-start">
                                    <FcDocument />
                                </div>
                                <Link to="/deposit" className='text-decoration-none'>
                                <div className="text-start content_history ">
                                    <h6 className="mb-0 capitalize">Deposit<span className="capitalize">My Deposit History</span></h6>

                                </div>
                                </Link>

                            </div>
                            
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card className="padding_custum_history mt-2">
                            <div className="d-flex align-items-center game_history">
                                <div className="game_history_icon d-flex justify-content-start">
                                    <FcDocument />
                                </div>
                                <Link to="/Withdraw" className='text-decoration-none'>
                                <div className="text-start content_history">
                                    <h6 className="mb-0 capitalize">Withdraw<span className="capitalize">My Withdraw History</span></h6>

                                </div>
                                </Link>

                            </div>
                        </Card>
                    </Col>
                </Row>
                <div className="Linksall">
                    <Link className="Links_all no-underline d-flex align-items-center">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="Notificationicon">
                                    <IoIosNotifications className="w-8 color_icon" />
                                </div>
                                <h2 className="mb-0 text-sm text-custum-color">Notification</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <Badge bg="danger">0</Badge>
                                <MdChevronRight className="w-8 text-custum-color" />
                            </div>
                        </div>
                    </Link>
                    <Link className="Links_all no-underline d-flex align-items-center">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="Notificationicon">
                                    <AiFillGift className="w-8 color_icon" />
                                </div>
                                <h2 className="mb-0 text-sm text-custum-color">Gifts</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <MdChevronRight className="w-8 text-custum-color" />
                            </div>
                        </div>
                    </Link>
                    {/* <Link className="Links_all no-underline d-flex align-items-center">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="Notificationicon">
                                    <FcStatistics className="w-8 color_icon" />
                                </div>
                                <h2 className="mb-0 text-sm text-custum-color">Game Statistics</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <MdChevronRight className="w-8 text-custum-color" />
                            </div>
                        </div>
                    </Link> */}
                    <Link className="Links_all no-underline d-flex align-items-center">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="Notificationicon">
                                    <FaLanguage className="w-8 color_icon" />
                                </div>
                                <h2 className="mb-0 text-sm text-custum-color">Language</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='mb-0 pb-0 text-custum-color  text-sm'>English</p>
                                <MdChevronRight className="w-8 text-custum-color" />
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="Linksall p-2">
                    <h2 className="text-sm text-start">Service center</h2>
                    <Row>
                    <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <FiSettings className="color_icon" />
                                </div>
                                Setting
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <MdFeedback className="color_icon" />
                                </div>
                                Feedback
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <IoIosNotifications className="color_icon" />
                                </div>
                                Notification
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <MdOutlineSupportAgent className="color_icon" />
                                </div>
                                24*7 Custumer Services
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <FaBookOpen className="color_icon" />
                                </div>
                                Begineer's guide
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="walleticon_de mt-2">
                                <div className="icon_all">
                                    <FaInfoCircle className="color_icon" />
                                </div>
                                About Us
                            </div>
                        </Col>

                      
                    </Row>
                </div>

                            <div>
                            <Link class="margin-bottom-89 Invitation_link d-flex align-items-center gap-2 
                            justify-content-center" onClick={handleLogout} ><TbLogout2/>Log Out</Link>
                            </div>
            </Container>

        </section>
    )
}   