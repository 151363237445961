import React from 'react'
import Logo from '../assets/images/Logo.png'
import { Container,Row,Col } from 'react-bootstrap'
import { FaChevronDown } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import {Link} from 'react-router-dom'
export default function Header() {
  return (
    <section className="Header_section">
        <Container>
        <div className="d-flex justify-content-between align-items-center">
           
                <div className="logo w-22">
                    <img src={Logo} className="w-22" alt="" />
                </div>
          
                <div className='d-flex '>
                  <p className=" text-white-700 header_icon">  <FaChevronDown className="hover:color-green:600  text-red-700"/></p>
                  <p className=" text-white-700 header_icon">  <IoMdDownload  className="hover:color-green:600  text-red-700"/></p>
                    
                </div>
            
        </div>
    </Container>
    </section>
  )
}
