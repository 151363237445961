import React from 'react'

export default function Dailytasks() {
  return (
   <section className="wallet">
     <div className='topheader p-2 bg-color-all'>
        
        </div>
   </section>
  )
}
