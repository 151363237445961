import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { FiUsers } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { FaCopy } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { BsFileRuled } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { LuBadgeDollarSign } from "react-icons/lu";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa6";


export default function Promotion() {


    return (
        <div className="Promotion padding_all">
            <div className="bg_color_page_home">
                <Container>
                    <div className="text-center">
                        <h3 className="mb-0 text-white">0</h3>
                        <p className="text-xs text-white bg-color-tag d-inline-block rounded-full">Yesterday's total commission</p>
                        <p className="text-xs text-white">Upgrade the level to increase commission income</p>
                    </div>
                    <div className="subcordinater  d-flex justify-content-center  align-items-start">
                        <div className="subcordinaterone">
                            <div className="card-sub d-flex justify-content-start align-items-center">
                                <FiUsers /><h5 className=' mb-0'>Direct Subcordinater</h5>
                            </div>
                            <div className='text-center details_subcoridnater'>
                                <h6>0</h6>
                                <p> number of register</p>
                                <h6>0</h6>
                                <p>  Deposit number</p>
                                <h6>0</h6>
                                <p>  Deposit amount</p>
                                <h6>0</h6>
                                <p>  Number of people making first deposit</p>
                            </div>
                        </div>
                        <div className="subcordinatersec">
                            <div className="card-sub d-flex justify-content-start align-items-center">
                                <FiUsers /><h5 className=' mb-0'>Direct Subcordinater</h5>
                            </div>
                            <div className='text-center details_subcoridnater'>
                                <h6>0</h6>
                                <p> number of register</p>
                                <h6>0</h6>
                                <p>  Deposit number</p>
                                <h6>0</h6>
                                <p>  Deposit amount</p>
                                <h6>0</h6>
                                <p>  Number of people making first deposit</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                {/*Invitation  Link  */}
                <Link to="/" className="Invitation_link">Invitation Link</Link>
                {/*Invitation  Link  */}
                <div className="invitaion_tab mt-3">
                    
                {/* copy invitation code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <FaCopy className="btn_icon" />
                            <p className="mb-0">Copy Invitation Code</p>
                        </div>
                        <div className="d-flex align-items-center">

                            <p className="mb-0">9876543210</p>
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* copy invitation code  */}
                {/*Subordinate data code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <BsFillClipboardDataFill className="btn_icon" />
                            <p className="mb-0">Subordinate data Code</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* Subordinate data code  */}
                {/*Commission detail code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <FaFileInvoiceDollar className="btn_icon" />
                            <p className="mb-0">Commission detail Code</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* Commission detail code  */}
                {/*Invitation Rule code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <BsFileRuled className="btn_icon" />
                            <p className="mb-0">Invitation Rule Code</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* Invitation Rule code  */}
                {/*Agent line customer service code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <MdSupportAgent className="btn_icon" />
                            <p className="mb-0">Agent line customer service Code</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* Agent line customer service code  */}
                {/*Rebate ratio code  */}
                <Card className="card_custum_new">
                    <div className="d-flex justify-content-between align-items-center invitation_tab_all">
                        <div className="d-flex align-items-center">
                            <LuBadgeDollarSign className="btn_icon" />
                            <p className="mb-0">Rebate ratio</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <MdOutlineKeyboardArrowRight className='right-icon' />
                        </div>
                    </div>
                </Card>
                {/* Rebate ratio code  */}
                
                </div>

                <div className="Linksall p-2">
                    <div className="d-flex gap-2">
                <FaDatabase className="color_icon"/>
                    <h2 className="text-sm text-start">Promotion Data</h2>
                    </div>
                    <Row>
                    <Col xs={6} className="border-r-2 border-zinc-300 mt-1">
                            <div className="promotion_setting mt-2">
                                <div className="amount_new">
0                                </div>
                                Setting
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="promotion_setting mt-2">
                                <div className="amount_new">
0                                </div>
                                Feedback
                            </div>
                        </Col>
                        <Col xs={6} className="border-r-2 border-zinc-300 mt-1">
                            <div className="promotion_setting mt-2">
                                <div className="amount_new">
0                                </div>
                                Notification
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="promotion_setting mt-2">
                                <div className="amount_new">
0                                </div>
                                24*7 Custumer Services
                            </div>
                        </Col>
                     

                      
                    </Row>
                </div>
            </Container>
        </div>
    );
}
