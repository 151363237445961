import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Container } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AiOutlineMail } from "react-icons/ai";
import { IoPhonePortraitSharp } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaLock } from "react-icons/fa6";
import { MdOutlineHeadphones } from "react-icons/md";
import Account from "./Account";
import { FaShieldAlt } from "react-icons/fa";
import { FaEnvelopeOpenText } from "react-icons/fa";
import flag from '../assets/images/download.png';
import { FaMobileAlt } from "react-icons/fa";
import Swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';

export default function Register() {
  const [mobile, setMobile] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isButtonDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled]);

  const validateForm = () => {
    const newErrors = {};
    if (!mobile) newErrors.mobile = "Phone number is required.";
    else if (!/^\d{10}$/.test(mobile)) newErrors.mobile = "Phone number must be 10 digits.";
    if (!otp) newErrors.otp = "Verification code is required.";
    if (!password) newErrors.password = "Password is required.";
    else if (password.length < 6) newErrors.password = "Password must be at least 6 characters.";
    if (!confirmedPassword) newErrors.confirmedPassword = "Please confirm your password.";
    else if (password !== confirmedPassword) newErrors.confirmedPassword = "Passwords do not match.";
    // if (!inviteCode) newErrors.inviteCode = "Invite code is required.";
    if (!agreed) newErrors.agreed = "You must agree to the terms.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSendOTP = async () => {
    if (!mobile || !/^\d{10}$/.test(mobile)) {
      setErrors({ ...errors, mobile: "Enter a valid 10-digit phone number." });
      return;
    }
    setIsButtonDisabled(true);
    setCountdown(25);

    try {
      const response = await fetch('https://apicolorgame.a2logicgroup.com/api/users/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mobile })
      });
      if (response.ok) {
        const data = await response.json();
        if (data.success === "1") {
          setOtp(data.otp);
          setOtpSent(true);
        } else {
          setError(data.message);
        }
      } else {
        setError("Failed to send OTP. Please try again later.");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const handleRegister = async () => {
    if (!validateForm()) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}Register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mobile, otp, password })
        });

        const data = await response.json();
        if (data.success === "1") {
            if (data.message === "User Register Success") {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                window.location.href = "/home"; 
            } else if (data.message === "Already Exist") {
                Swal.fire({
                    title: 'Mobile Number Already Exists',
                    text: 'Please try with a different mobile number.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if (data.status === "0") {
                Swal.fire({
                    title: 'Invalid Referral Code',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } else {
            Swal.fire({
                title: 'Registration Failed',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    } catch (error) {
        console.error('Error sending OTP:', error);
        setError("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <section className="Login_section">
      <div className="Login">
        <Container>
          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="arrow_login">
              <Link to="/">
                <IoIosArrowBack className="text-white" />
              </Link>
            </div>
            <div className="headinglogin">
              <h5 className="text-white mb-0">Register</h5>
            </div>
            <div className="d-flex justify-around">   
              <img className="lan_flag" src={flag} alt="" /> &nbsp;
              <p className="language text-white">En</p>
            </div>
          </div>
          <div className="text-start Login_heading text-white">
            <h1 className="">Register</h1>
            <p className="mb-0">Please register by phone number or email</p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="login_method">
          <Tabs>
            <TabList>
              <Tab>
                <div className="width-tab">
                  <div className="icon_login">
                    <FaMobileAlt />
                  </div>
                  <h6 className="mb-0">Register your phone</h6>
                </div>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="Login_form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <IoPhonePortraitSharp />
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setMobile(e.target.value)}
                      type="tel"
                      placeholder="Enter Phone Number"
                      className="input_login_radius_custum"
                    />
                    {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaShieldAlt />
                      Verification Code
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Control
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        placeholder="Please enter the confirmation code"
                        className="input_login_radius_custum"
                      />
                      <Button 
                        className="send_button" 
                        onClick={handleSendOTP} 
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled ? `Resend (${countdown}s)` : (otpSent ? "Resend" : "Send")}
                      </Button>
                    </div>
                    {errors.otp && <div className="text-danger">{errors.otp}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                      Set Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Set Password"
                      className="input_login_radius_custum"
                    />
                    {errors.password && <div className="text-danger">{errors.password}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setConfirmedPassword(e.target.value)}
                      type="password"
                      placeholder="Confirm Password"
                      className="input_login_radius_custum"
                    />
                    {errors.confirmedPassword && <div className="text-danger">{errors.confirmedPassword}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaEnvelopeOpenText />
                      Invite Code
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setInviteCode(e.target.value)}
                      type="text"
                      placeholder="Please enter the invite code"
                      className="input_login_radius_custum"
                    />
                    {errors.inviteCode && <div className="text-danger">{errors.inviteCode}</div>}
                  </Form.Group>
                  <Form.Group className="agree_check mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I have read and agree"
                      checked={agreed}
                      onChange={() => setAgreed(!agreed)}
                    />
                    {errors.agreed && <div className="text-danger">{errors.agreed}</div>}
                  </Form.Group>
                  <div className="register_btn_page" onClick={handleRegister}>
                    Register
                  </div>
                  <Link className="ac" to="/">
                    <div className="register_btn mb-5">
                      <span>I have an Account</span> 
                      <button>Login</button>
                    </div>
                  </Link>
                </Form>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    </section>
  );
}
