import React from 'react'
import { FaIndianRupeeSign } from "react-icons/fa6";

export default function Winpoup() {
  return (
    <div className="winimage">
            <div className="content_win ">
                <h3 className="congrets">Congratulations</h3>
                <div className="result_name d-flex justify-content-between align-items-center">
                        <p className="mb-0 pb-0">Result Name</p>
                        <p className="mb-0 pb-0">Red</p>
                        <p className="mb-0 pb-0">1</p>
                        <p className="mb-0 pb-0">Win</p>

                </div>
                <h4>Bonus</h4>
                <h3 className="d-flex justify-content-center"><FaIndianRupeeSign/> 1.00</h3>
                <h6 className="d-flex justify-content-center winingamountperied">Peried Win: 1 Minutes 202020202020</h6>
            </div>
    </div>
  )
}
