import React from 'react';
import { Container, Row, Col, Card, Alert, Form, InputGroup } from 'react-bootstrap';
import { MdOutlineSecurity } from "react-icons/md";
import { FaLock } from "react-icons/fa6";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";

export default function Verification() {
    return (
        <section className="verification">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 className="text-center">Account has been locked</h3>
                        <Alert variant="danger" className='alert_custum'>
                            <p className="mb-0">You Have Entered Wrong Password More Then 50 Times</p>
                            <p className="mb-0">For account security, please complete account security verification</p>
                        </Alert>
                        <Card>
                            <Card.Body className="p-2">
                                <div className="mb-2">
                                    <div className="verficationnumber d-flex align-items-center gap-1">
                                        <MdOutlineSecurity />   <p className="mb-0">Send verification code to +91987****3210</p>
                                    </div>
                                    <div className="d-flex justify-content-between gap-4 mt-2">
                                        <InputGroup>
                                            <Form.Control className="input_login_radius_custum " placeholder="Enter Verification Code" />

                                        </InputGroup>
                                        <div className="d-flex justify-content-center">
                                            <button className='resendcode'>Send</button>

                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="verficationnumber d-flex align-items-center gap-1">
                                        <FaLock />   <p className="mb-0">A New Password</p>
                                    </div>
                                    <div className="d-flex justify-content-between gap-4 mt-2">
                                        <InputGroup>
                                            <Form.Control className="input_login_radius_custum " placeholder="Confirm New Password" />

                                        </InputGroup>

                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="verficationnumber d-flex align-items-center gap-1">
                                        <FaLock />   <p className="mb-0">Confirm New Password</p>
                                    </div>
                                    <div className="d-flex justify-content-between gap-4 mt-2">
                                        <InputGroup>
                                            <Form.Control className="input_login_radius_custum " placeholder="Confirm New Password" />

                                        </InputGroup>

                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="d-flex justify-content-center align-items-center gap-2 custumer_services">
                                    <MdOutlineMarkUnreadChatAlt/> <button>Contact Custumer Services</button>
                                    </div>
                                </div>
                                <p className="text-center text-danger"> Wrong phone number <span className="d-block">Did not receive the OTP code, please contact customer service</span></p>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}