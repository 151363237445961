import {React,useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col, Badge } from 'react-bootstrap';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { IoMdWallet } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import chip from '../assets/images/chip.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaCreditCard } from "react-icons/fa6";
import { FaBook } from "react-icons/fa";
import { BiSolidWallet } from "react-icons/bi";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import axios from 'axios';
export default function Deposit() {

  const user_id = localStorage.getItem("user_id");
  const isLoading = useRef(false);
  const credit = localStorage.getItem('userCredit');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const [amount, setAmount] = useState('');

  const handleAmountClick = (value) => {
    setAmount(value);
  };

  useEffect(() => {
    if (!isLoading.current) {
      fetchGames();
    }
  }, []);
  const fetchGames = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}profile-list`;
  
      setLoading(true);
  
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };
  
      const response = await axios(config);

      const responseData = response.data.data;
      setUserData(responseData);
      const credit = responseData.credit;
      // alert(credit);
    localStorage.setItem('userCredit', credit);

      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };


  return (
    <section className='withdraw'>
      <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
        <Link to="/Wingo" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft/></Link>
        <h4 className="mb-0 text-white">Deposit</h4>
        <Link to="/Deposithistory" className='w-5 text-white'>
        <p className='mb-0 float-end'>Deposit History</p>
        </Link>
      </div>
      <Container>
        <div className="card_design_custum p-2">
          <div className="d-flex align-items-center">
            <IoMdWallet />
            <p className="mb-0 text-sm">Available Balance</p>
          </div>
          <h3 className="d-flex align-items-center mt-2">
            <MdCurrencyRupee />
            <span className="mx-1"> {credit} </span>
            <MdRefresh />
          </h3>
          <div className='d-flex justify-content-between align-items-center'>
            <div className="chip">
              <img src={chip} className="" />
            </div>
            <p className="mb-0 text-sm">*** ****</p>
          </div>
        </div>
        <div className="withdrawaltab">
        <Tabs>

    <TabPanel>

    <div className="card_custum rounded-sm mt-3">
      <div className="d-flex justify-content-center p-2 flex-col gap-2">
        <div className="d-flex justify-content-start text-dark">
          <BiSolidWallet style={{ color: "#fa7638" }} />
          &nbsp; &nbsp;
          <h6>Deposit Amount</h6>
        </div>
        <div className="row d-flex justify-content-around">
          {[100, 200, 400, 600, 800, 300].map((value, index) => (
            <div key={index} className={`col-4 ${index >= 3 ? 'mt-3' : ''}`}>
              <div
                className="amountbox"
                onClick={() => handleAmountClick(value)}
                style={{ cursor: 'pointer' }}
              >
                <MdCurrencyRupee />
                <h4>{value}</h4>
              </div>
            </div>
          ))}
          <div className="mt-3">
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <MdCurrencyRupee style={{ color: "#fa7638" }} />
              </InputGroup.Text>
              <Form.Control
                aria-label="Amount (to the nearest dollar)"
                value={amount}
                readOnly
              />
              <InputGroup.Text>
                <CloseButton onClick={() => setAmount('')} />
              </InputGroup.Text>
            </InputGroup>
            <div>
              <Link className="Invitation_link d-flex align-items-center gap-2 justify-content-center">
                Deposit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="card_custum rounded-sm mt-3 ">
          <div className="d-flex justify-content-center p-3  flex-col gap-2">
            <div className="d-flex justify-content-start text-dark"><FaBook style={{color:"#fa7638"}} />&nbsp; &nbsp; <h6>Recharge instructions</h6></div>
            <Card border="gray" style={{ width: 'auto' }}>
        <Card.Body>
        <div className=" list_withdraw	rounded-sm	text-start ">
          <p className="mb-1   text-base text-sm text-blue-900	">If the transfer tiem is up, please fill out the deposit form again</p>
          <p className="mb-1   text-base text-sm text-blue-900	">The transfer amount must match the order you created, otherwise the money cannot be credited successfully</p>
          <p className="mb-1   text-base text-sm text-blue-900	">If you transfer the wrong amount, our company will not be responsible for the lost amount </p>
          <p className="mb-1   text-base text-sm text-blue-900	">Note: do not cancel the deposit order after the money has been transferred.</p>
        </div>
        </Card.Body>
      </Card>
          </div>
      </div>

     

    </TabPanel>
    {/* <TabPanel>
      <h2>Any content 2</h2>
    </TabPanel> */}
  </Tabs>
        </div>
     

      </Container>

    </section>
  )
}
