import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";
import { SiNeteasecloudmusic } from "react-icons/si";
import { Card, Container, Modal } from 'react-bootstrap';
import { TfiWallet } from "react-icons/tfi";
import { MdCurrencyRupee } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { AiFillSound } from "react-icons/ai";
import { FaGripfire } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { IoIosClock } from "react-icons/io";
import { IoDocumentTextSharp } from "react-icons/io5";
import One from '../../assets/images/one.png';
import Two from '../../assets/images/two.png';
import Three from '../../assets/images/three.png';
import Four from '../../assets/images/four.png';
import Five from '../../assets/images/five.png';
import Six from '../../assets/images/six.png';
import Seven from '../../assets/images/seven.png';
import Eight from '../../assets/images/eight.png';
import Nine from '../../assets/images/nine.png';
import Zero from '../../assets/images/zero.png';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function Wintrx() {
    const [activeButtons, setActiveButton] = useState(null);

    const toggleClass = (buttonId) => {
        setActiveButton(buttonId === activeButtons ? null : buttonId);
    };

    //   model 
    const [showmodel, setShowmodel] = useState(false);

    const handlemodelclose = () => setShowmodel(false);
    const handleShowmodel = () => setShowmodel(true);
    //   model 

    const [activebtn, setActivebtn] = useState(null);

    const toggleClassamt = (buttonId) => {
        setActivebtn(buttonId === activebtn ? null : buttonId);
    };






    const [show, setShow] = useState(false);
    const [placement, setPlacement] = useState(''); // State to store the placement value
    const [count, setCount] = useState(0);
    function increment() {
        setCount(prevCount => prevCount + 1);
    };
    function decrement() {
        setCount(prevCount => prevCount - 1);
    };
    const handleClose = () => setShow(false);
    const handleShow = (placement) => {
        setShow(true);
        setPlacement(placement); // Set the placement value when the button is clicked
    };
    const placements = ['Green', 'Violet', 'Red'];
    const bigsmall = ['big', 'small'];
    function OffCanvasExample({ name, ...props }) {

        return (
            <>
                {placements.map((placement, index) => (
                    <button
                        key={index}
                        variant="primary"
                        onClick={() => handleShow(placement)}
                        className="me-2 button_color_game"
                    >
                        {name} {placement}
                    </button>
                ))}

                {/* The "Big" button */}

                {/* OffCanvas component */}
                {/* closeButton */}
                <Offcanvas show={show} onHide={handleClose} placement={placement} className="offcanvas_custum">
                    <Offcanvas.Header className="d-flex justify-content-center bg_color_header">
                        <Offcanvas.Title>
                            <div className="titlegame">
                                <h3>Win Go 1Min</h3>
                                <h4>Select Small</h4>
                            </div>

                        </Offcanvas.Title>

                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="d-flex justify-content-between align-items-center w-100" >
                            <p className="mb-0 text-sm">Balance</p>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className={`button ${activeButtons === 1 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(1)} >
                                    1
                                </button>
                                <button className={`button ${activeButtons === 2 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(2)} >
                                    10
                                </button>
                                <button className={`button ${activeButtons === 3 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(3)} >
                                    100
                                </button>
                                <button className={`button ${activeButtons === 4 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(4)} >
                                    1000
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mt-1" >
                            <p className="mb-0 text-sm">Quantity </p>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className='counterbtnsd' onClick={increment}>-</button>
                                <input className="inputcount" value={count} />
                                <button className='counterbtnsd' onClick={decrement}>+</button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-1">
                            <button className={`button ${activeButtons === 8 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(8)} >
                                X1
                            </button>
                            <button className={`button ${activeButtons === 9 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(9)} >
                                X5
                            </button>
                            <button className={`button ${activeButtons === 10 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(10)} >
                                X10
                            </button>
                            <button className={`button ${activeButtons === 11 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(11)} >
                                X20
                            </button>
                            <button className={`button ${activeButtons === 12 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(12)} >
                                X50
                            </button>
                            <button className={`button ${activeButtons === 13 ? "active_btn" : ""} btn_amt `} onClick={() => toggleClassamt(13)} >
                                X100
                            </button>

                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="d-flex align-items-center">
                                <input id="03" type="radio" name="r" value="3" className="mr-1" />
                                <label for="03" className='text-danger text-sm'>I Agree</label>
                            </div>
                            <button variant="primary" onClick={handleShowmodel}>
                                <h6 className="text-sm ml-2 text-danger mb-0"> {'<<'}Pre Rule{'>>'}</h6>
                            </button>

                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="closebtn">
                                cancel
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
    return (
        <section className='Wintrx padding_all'>
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Wintrx</h4>
                <div className='d-flex'>
                    <Link to="/" className='w-5 text-white'><MdOutlineSupportAgent /></Link>
                    <SiNeteasecloudmusic className='w-5 text-white' />
                </div>
            </div>
            <div className='allbefore position-relative'>
                <Container>
                    <Card className='mt-2 points_left_right'>
                        <Card.Body>
                            <h3 className="text-dark d-flex mb-0 align-items-center  gap-1 justify-content-center"><MdCurrencyRupee className="text-sm" />0.00 <IoMdRefresh className='text-sm text-muted' /></h3>
                            <p className="d-flex mb-0 align-items-center  gap-1 justify-content-center"><TfiWallet />Wallet Balance</p>
                            <div className="d-flex justify-content-evenly align-items-center">
                                <Link to="/" className="buttonall  no-underline rounded-full" style={{ "--bgcolor": "#fa7638" }}>
                                    <span>Withdraw</span>
                                </Link>
                                <Link to="/" className="buttonall  no-underline rounded-full" style={{ "--bgcolor": "#90403f" }}>
                                    <span>Deposit</span>
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="Marquee_Tag bg-white">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="h-25 w-28 sound_icon">
                                <AiFillSound />
                            </div>
                            <div className="Notice">
                                <p className="">Our customer service never send a link to the member,if you received a link from someone who pro-claimed as 91club customer service do not click the link to prevent being hack or lost data. Thank you</p>
                            </div>

                            <div className="details">
                                <button className="text-slate-900	 text-white d-flex"><FaGripfire />Details</button>
                            </div>
                        </div>

                    </div>
                    <div className="windgo_tabs">
                        <Tabs>
                            <TabList className="d-flex justify-content-between pl-0 bg-white rounded p-1">
                                <Tab>
                                    <div className="tabs_windgo">
                                        <div className="d-flex justify-content-center align-items-center icon_rounded">
                                            <IoIosClock />
                                        </div>
                                        <p>Win Go<span> 1 Min</span></p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="tabs_windgo">
                                        <div className="d-flex justify-content-center align-items-center icon_rounded">
                                            <IoIosClock />
                                        </div>
                                        <p>Win Go<span> 3 Min</span></p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="tabs_windgo">
                                        <div className="d-flex justify-content-center align-items-center icon_rounded">
                                            <IoIosClock />
                                        </div>
                                        <p>Win Go<span> 5 Min</span></p>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="tabs_windgo">
                                        <div className="d-flex justify-content-center align-items-center icon_rounded">
                                            <IoIosClock />
                                        </div>
                                        <p>Win Go<span> 10 Min</span></p>
                                    </div>
                                </Tab>

                            </TabList>

                            <TabPanel>
                                <div className="divide-y divide-dashed bg-color-all tab_active p-2 rounded border_offset">
                                    <div className="d-flex justify-content-between align-items-start">
                                        <div className="Howtoplay">
                                            <div className="d-flex gap-1">
                                                <h5 className="mb-0 d-flex align-items-center justify-content-center bordered rounded-md">Period</h5>
                                            <h5 className="mb-0 bg-white d-flex align-items-center justify-content-center gap-1 text-dark">How To Play</h5>
                                            </div>
                                            
                                            <h6 className="mb-0 text-sm text-white text-start mt-1 mb-0">20240430131131</h6>
                                            
                                        </div>
                                        <div className="Countdown_timer">
                                            <h5 className='text-white text-sm'>Time Remaining</h5>
                                            <div className="d-flex justify-content-between countdown_time">
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">:</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                            </div>
                                            <h5 className='text-white text-sm mt-1 mb-0'>854712589632</h5>

                                        </div>
                                    </div>
                                   
                                    <div className="d-flex justify-content-between flex-wrap gap-1 mt-1 pt-1">
                                                <div className="w-10 h-10">
                                                    <img src={One} />
                                                </div>
                                                <div className="w-10 h-10">
                                                    <img src={Two} />

                                                </div>
                                                <div className="w-10 h-10">
                                                    <img src={Three} />

                                                </div>
                                                <div className="w-10 h-10">
                                                    <img src={Four} />

                                                </div>
                                                <div className="w-10 h-10">
                                                    <img src={Five} />
                                                </div>
                                               
                                            </div>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="bg-color-all tab_active p-2 rounded border_offset">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="Howtoplay">
                                            <h5 className="mb-0 d-flex align-items-center justify-content-center gap-1"><IoDocumentTextSharp />How To Play</h5>
                                            <p>Win Go 1 Min</p>
                                            <div className="d-flex justify-content-between gap-1 mt-1">
                                                <div className="w-6 h-6">
                                                    <img src={One} />
                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Two} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Three} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Four} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Five} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Countdown_timer">
                                            <h5 className='text-white text-sm'>Time Remaining</h5>
                                            <div className="d-flex justify-content-between countdown_time">
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">:</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                            </div>
                                            <h5 className='text-white text-sm mt-1'>854712589632</h5>

                                        </div>
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="bg-color-all tab_active p-2 rounded border_offset">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="Howtoplay">
                                            <div className="d-flex align-items-center"> 
                                            <h5 className="mb-0 d-flex align-items-center justify-content-center gap-1">Period</h5>
                                                <h5 className="mb-0 d-flex align-items-center justify-content-center gap-1"><IoDocumentTextSharp />How To Play</h5>
                                            </div>

                                            <h6>9854751254225</h6>
                                            
                                        </div>
                                        <div className="Countdown_timer">
                                        <h5 className="mb-0 bg-white d-flex align-items-center justify-content-center gap-1">Public Chain Query</h5>
                                            <div className="d-flex justify-content-between countdown_time">
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">:</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                            </div>
                                            <h5 className='text-white text-sm mt-1'>854712589632</h5>

                                        </div>
                                    </div>
                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="bg-color-all tab_active p-2 rounded border_offset">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="Howtoplay">
                                            <h5 className="mb-0 d-flex align-items-center justify-content-center gap-1"><IoDocumentTextSharp />How To Play</h5>
                                            <p>Win Go 1 Min</p>
                                            <div className="d-flex justify-content-between gap-1 mt-1">
                                                <div className="w-6 h-6">
                                                    <img src={One} />
                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Two} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Three} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Four} />

                                                </div>
                                                <div className="w-6 h-6">
                                                    <img src={Five} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Countdown_timer">
                                            <h5 className='text-white text-sm'>Time Remaining</h5>
                                            <div className="d-flex justify-content-between countdown_time">
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">:</div>
                                                <div className="countdown">0</div>
                                                <div className="countdown">0</div>
                                            </div>
                                            <h5 className='text-white text-sm mt-1'>854712589632</h5>

                                        </div>
                                    </div>
                                </div>

                            </TabPanel>

                        </Tabs>
                    </div>

                    <div className="game_board">
                        <Card>
                            <Card.Body className="p-1">
                                <div className="d-flex align-items-center justify-content-between">

                                    <OffCanvasExample placement="bottom" />
                                </div>
                                <div className="bg-gray-200 rounded p-2 mt-1">
                                    <div className="d-flex justify-content-between flex-wrap gap-2 mt-1">
                                        <div className="w-12 h-12">
                                            <img src={One} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Two} />

                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Three} />

                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Four} />

                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Five} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Six} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Seven} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Eight} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Nine} />
                                        </div>
                                        <div className="w-12 h-12">
                                            <img src={Zero} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <button className="border text-danger multiple_winredom">
                                        Rendom
                                    </button>
                                    <button
                                        className={`button ${activeButtons === 1 ? "active" : ""} multiple_win`} onClick={() => toggleClass(1)} > X1</button>
                                    <button className={`button ${activeButtons === 2 ? "active" : ""} multiple_win`}
                                        onClick={() => toggleClass(2)}
                                    > X5</button>
                                    <button
                                        className={`button ${activeButtons === 3 ? "active" : ""} multiple_win`}
                                        onClick={() => toggleClass(3)}
                                    > X10</button>
                                    <button
                                        className={`button ${activeButtons === 4 ? "active" : ""} multiple_win`}
                                        onClick={() => toggleClass(4)}
                                    > X20</button>
                                    <button
                                        className={`button ${activeButtons === 5 ? "active" : ""} multiple_win`}
                                        onClick={() => toggleClass(5)}
                                    > X50</button>
                                    <button
                                        className={`button ${activeButtons === 6 ? "active" : ""} multiple_win`}
                                        onClick={() => toggleClass(6)}
                                    >
                                        X100</button>
                                </div>
                                <div className="d-flex align-items-center justify-content-evenly mt-1">
                                    {bigsmall.map((bigsmall, index) => (
                                        <button
                                            key={index}
                                            variant="primary"
                                            onClick={() => handleShow(bigsmall)}
                                            className="me-2 button_color_big_small"
                                        >
                                            {bigsmall.name} {bigsmall}
                                        </button>
                                    ))}
                                </div>

                                {/* </div> */}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="history mt-2">
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <button className="">Game History</button>
                                </Tab>
                                <Tab>
                                    <button className="">Chart</button>
                                </Tab>
                                <Tab>
                                    <button className="">My History</button>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <table class="table-auto table table-striped history_table">
                                    <thead>
                                        <tr>
                                            <th className="">Peried</th>
                                            <th className="">Number</th>
                                            <th className="">Big Small</th>
                                            <th className="">Color</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>20240426010888</td>
                                            <td><span className="number">2</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>20240426010888</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>20240426010888</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>20240426010888</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr >
                                        <tr>
                                            <td>20240426010888</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table >

                            </TabPanel >
                            <TabPanel>
                                <table class="table-auto table table-striped history_table">
                                    <thead>
                                        <tr>
                                            <th className="">Peried</th>
                                            <th className="">Number</th>
                                            <th className="">Big Small</th>
                                            <th className="">Color</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>87542584248</td>
                                            <td><span className="number">2</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>87542584248</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>87542584248</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>87542584248</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr >
                                        <tr>
                                            <td>87542584248</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table >

                            </TabPanel >
                            <TabPanel>
                                <table class="table-auto table table-striped history_table">
                                    <thead>
                                        <tr>
                                            <th className="">Peried</th>
                                            <th className="">Number</th>
                                            <th className="">Big Small</th>
                                            <th className="">Color</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>47854214574</td>
                                            <td><span className="number">2</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>47854214574</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>47854214574</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>47854214574</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr >
                                        <tr>
                                            <td>47854214574</td>
                                            <td><span className="number">5</span></td>
                                            <td>Big</td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-evenly">
                                                    <span className="w-2 h-2 rounded-full bg-danger d-inline-block"></span>
                                                    <span className="w-2 h-2 rounded-full bg-primary d-inline-block"></span>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table >

                            </TabPanel >

                        </Tabs >
                    </div >
                </Container >
            </div >

            <Modal show={showmodel} onHide={handlemodelclose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" onClick={handlemodelclose}>
                        Close
                    </button>
                    <button variant="primary" onClick={handlemodelclose}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </section >
    )
}
