import React from 'react';
import {Row,Col,Card} from 'react-bootstrap';
import { LuBarChart3 } from "react-icons/lu";
import profile from '../assets/images/profile.png'
import silver from '../assets/images/silver.png'
import golden from '../assets/images/golden.png'
import bronze from '../assets/images/bronze.png'
import { MdCurrencyRupee } from "react-icons/md";

export default function Todayearningchart(){
    return(
        <section className="todayearning">
         <Row>
                <Col xs={12}>
                    <div className="heading">
                        <div className="box">
                            <LuBarChart3 className='trophy' />

                            <p>Today Earning Chart
                                <span className="design_heading"></span>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={12} >
                    <div className="mt-14">
                    <div className="ranking_page">
                        <div className="d-flex justify-content-between">
                            <div className="winner">
                                <div className="winner2">
                                     <picture className="d-flex justify-content-center">

                                        <img src={silver} alt="Profile Image" className="kingsivercrown rounded-full"/>
                                        <img src={profile} alt="Profile Image" className="w-14 h-14 rounded-full"/>
                                    </picture> 
                                    <div className="game_id">deepa****k</div>
                                    <div className=" d-flex amount"><MdCurrencyRupee/>25,854,847.00</div>
                                </div>
                            </div>
                            <div className="winner">
                                <div className="winner1">
                                <picture className="d-flex justify-content-center">
                                        <img src={golden} alt="Profile Image" className="kingsivercrown rounded-full"/>
                                        <img src={profile} alt="Profile Image" className="w-14 h-14 rounded-full"/>
                                    </picture> 
                                    <div className="game_id">deepa****k</div>
                                    <div className=" d-flex amount"><MdCurrencyRupee/>25,854,847.00</div>
                                </div>
                            </div>
                            <div className="winner">
                                <div className="winner3">
                                <picture className="d-flex justify-content-center">
                                        <img src={bronze} alt="Profile Image" className="kingsivercrown rounded-full"/>
                                        <img src={profile} alt="Profile Image" className="w-14 h-14 rounded-full"/>
                                    </picture> 
                                    <div className="game_id">deepa****k</div>
                                    <div className=" d-flex amount"><MdCurrencyRupee/>25,854,847.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                   
                <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>4</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                               <button className='rounded-full button_color d-flex align-items-center'>
                              <MdCurrencyRupee/>   18,000,00.00
                                </button>   
                            </div>
                        </div>
                    </Card>
                <Card className="px-3 py-1 mb-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='mb-0 text-sm'>5</p>
                            <div className="d-flex align-items-center">
                                    <picture>
                                        <img src={profile} alt="Profile Image" className="w-10 h-10 rounded-full"/>
                                    </picture> 
                                    <div className="id_user">
                                        <p className="mb-0 ml-1 text-xs">Dyane*****on</p>
                                    </div>
                            </div>
                            <div  className="d-flex align-items-center">
                               <button className='rounded-full button_color d-flex align-items-center'>
                              <MdCurrencyRupee/>   18,000,00.00
                                </button>   
                            </div>
                        </div>
                    </Card>
                </Col>
                </Row>
        </section>
    )
}