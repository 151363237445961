import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [params, setParams] = useState({
    categorySlug: '',
    categoryName: '',
    subCategorySlug: '',
    subCategoryName: ''
  });

  return (
    <AppContext.Provider value={{ params, setParams }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
