import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col, Badge, Table } from 'react-bootstrap';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import nodata from '../assets/images/nodata.png';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { MdDashboard } from "react-icons/md";
import { MdQrCodeScanner } from "react-icons/md";
import { SiPaytm } from "react-icons/si";
import { IoDiamond } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { GoCopy } from "react-icons/go";
import Swal from 'sweetalert2';

export default function Deposithistory() {
    // model type all pedning processing
    const [displayedValue, setDisplayedValue] = useState('All');

    const [isPopupOpen, setPopupOpen] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    }
    const [copytext,setCopytext] = useState();

    const copytextorder = () =>{
        navigator.clipboard.writeText(copytext)
        .then(() =>{
            Swal.fire({
                title: "Order Number Copy Successfully ",
                icon: "success"
              });
        })
        .catch(err =>{
            console.log('helo', err);
        })

    };


    function CustomModelPopup({ isOpen, onClose }) {

        const [selectedValue, setSelectedValue] = useState('');

        const handleSelectChange = (event) => {
            setSelectedValue(event.target.value);
        };

        const handleButtonClick = () => {
            setDisplayedValue(selectedValue);
        };
        const handleMouseWheel = (event) => {
            const delta = Math.sign(event.deltaY);
            listRef.current.scrollTop += delta * 30; // Adjust scrolling speed as needed
        };
        const listRef = useRef(null);

        // Function to handle the list item click event
        const handleListItemClick = (value) => {
            setSelectedValue(value);
        };
        return (
            <div className={`custom-model-popup ${isOpen ? 'open' : ''}`}>
                <div className="popup-content">
                    <div className='d-flex justify-content-between align-items-center'>
                        <button onClick={onClose}>Close</button>
                        <button onClick={handleButtonClick} className="text-blue">Confirm</button>
                    </div>
                    <ul
                        ref={listRef}
                        className="withdrawal_status"
                        onWheel={handleMouseWheel}
                    >
                        <li
                            onClick={() => handleListItemClick('All')}
                            className={selectedValue === 'All' ? 'highlighted' : ''}
                        >
                            All
                        </li>
                        <li
                            onClick={() => handleListItemClick('Processing')}
                            className={selectedValue === 'Processing' ? 'highlighted' : ''}
                        >
                            Processing
                        </li>
                        <li
                            onClick={() => handleListItemClick('Completed')}
                            className={selectedValue === 'Completed' ? 'highlighted' : ''}
                        >
                            Completed
                        </li>
                        <li
                            onClick={() => handleListItemClick('Rejected')}
                            className={selectedValue === 'Rejected' ? 'highlighted' : ''}
                        >
                            Rejected
                        </li>

                    </ul>

                </div>
            </div>
        );
    }
    // model type all pedning processing


    // date range picker model


    // date range picker model

    return (
        <section className='Deposithistory'>
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/Wingo" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Deposit History</h4>
                <Link to="/Deposit" className='w-5'></Link>
            </div>

            {/* tabs  */}
            <Container>
                <div className="history_type">
                    <Tabs>
                        <TabList  className="d-flex overflow-y-hidden overflow-x-scroll pl-0 align-items-center">
                            <Tab>
                                <div className="d-flex align-items-center">
                                <MdDashboard/>   All
                                </div>

                            </Tab>
                            <Tab>
                                <div className="d-flex align-items-center">
                                  < MdQrCodeScanner/> UPI
                                </div>
                            </Tab>
                            <Tab>
                                <div className="d-flex align-items-center">
                                < MdQrCodeScanner/>  UPI Transfer</div></Tab>
                            <Tab> 
                                
                                 <div className="d-flex align-items-center">
                                 < MdQrCodeScanner/>  UPI Pay
                                </div>
                                </Tab>
                            <Tab><div className="d-flex align-items-center"><SiPaytm/>Paytm</div></Tab>
                            <Tab><div className="d-flex align-items-center"><IoDiamond/>USDT </div></Tab>
                            <Tab><div className="d-flex align-items-center"><IoDiamond/>TRX  </div></Tab>
                        </TabList>

                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <Card className="due_fees mt-3">
                                <Card.Body>
                                <div className="fee_card">
                        
                        <div className="d-flex justify-content-between mb-2">
                            <div className='deposit_status bg-success'>Deposit</div>
                         
                         <h6 className='text-danger'>Failed</h6>

                         
                         </div>

                        <div>
                            <div className="d-flex justify-content-between algin-items-center">
                              <h6><small>Balance</small></h6>
                              <h6 className="d-flex gap-1"><MdCurrencyRupee/><small>100</small></h6>
                            </div>
                            <div className="d-flex justify-content-between algin-items-center">
                              <h6><small>Type</small></h6>
                              <h6><small>UPI-S</small></h6>
                            </div>
                            <div className="d-flex justify-content-between algin-items-center">
                              <h6><small>Time</small></h6>
                              <h6><small>2024-12-23 12:23:00</small></h6>
                            </div>
                            <div className="d-flex justify-content-between algin-items-center">
                              <h6><small>Order Number</small></h6>
                              <h6 className="gap-1 d-flex"><small>p0574857847548898</small><GoCopy onClick={copytextorder}/></h6>
                            </div>
                          
                        </div>
                      </div>
                                </Card.Body>
                        {/* {assign.created_date} */}
                      </Card>

                            {/* <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div> */}


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>
                        <TabPanel>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div onClick={togglePopup} className="All_type d-flex justify-content-between align-items-center" >
                                    <p >{displayedValue}</p>
                                    <MdOutlineKeyboardArrowRight />
                                </div>
                                <div className="All_type" >
                                    <DateRangePicker>
                                        <input type="text" className="form-control datepicker" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="w-50 h-50 mx-auto pt-4">
                                <img src={nodata} className='img-fluid' />
                            </div>


                        </TabPanel>

                    </Tabs>
                </div>

            </Container>
            {/* tabs  */}
            <CustomModelPopup isOpen={isPopupOpen} onClose={togglePopup} />

        </section>
    )
}
