import { React, useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderimage from "../assets/images/sliderimage.jpg";
import poolball from "../assets/images/poolball.png";
import lottery from "../assets/images/lottery.png";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillSound } from "react-icons/ai";
import { FaGripfire } from "react-icons/fa";
import { TfiViewGrid } from "react-icons/tfi";
import Wininginfo from "./Wininginfo";
import Todayearningchart from "./Todayearningchart";
import Swal from "sweetalert2";
import axios from "axios";
import loader from '../assets/images/loader.gif';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useData } from "../Pages/AllLotteryGames/DataContext";
import { useAppContext } from './AllLotteryGames/AppContext';

export default function Home() {
  var sliderhome = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const { data, setData } = useData();
  const user_id = localStorage.getItem("user_id");
  // const slug = localStorage.getItem("category_slug");
  const [userData, setUserData] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [slug, setSlug] = useState();
  const [sliderData, setSliderData] = useState(null);
  const isLoading = useRef(false);
  const [loading, setLoading] = useState(false);
  const [images, setImage] = useState([]);
  const [gameimages, setGameImage] = useState([]);
  const [sliderimg, setSliderImg] = useState([]);

  const navigate = useNavigate();


  const { setParams } = useAppContext();

  const handleNavigation = () => {
    setParams({
      categorySlug,
      categoryName,
      subCategorySlug,
      subCategoryName
    });
  };

  useEffect(() => {
    if (!isLoading.current) {
      fetchGames();
      if (user_id == null) {
        navigate("/");
      }
      fetchSlider();
    }
  }, []);

  const [categorySlug, setCategorySlug] = useState(null);
  const [subCategorySlug, setSubCategorySlug] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);


  const fetchGames = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}game-category`;
      setLoading(true);

      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };

      const response = await axios(config);
      const responseData = response.data.data;
      setUserData(responseData);

      if (responseData.length > 0) {
        const firstItem = responseData[0];
        setCategorySlug(firstItem.slug);
        setCategoryName(firstItem.name);
        setData(prevData => ({
          ...prevData,
          category: {
            slug: firstItem.slug,
            id: firstItem._id,
            name: firstItem.name,
          }
        }));
      //   localStorage.setItem("category_slug", firstItem.slug);
      // localStorage.setItem("category_id", firstItem._id);
      // localStorage.setItem("category_name", firstItem.name);
        fetchGameCategory(user_id, firstItem.slug);
      }

      const completeImageUrl = `${response.data.urlImage}`;
      setImage(completeImageUrl);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const fetchGameCategory = async (user_id, slug) => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}game-sub-category`;
      setLoading(true);

      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id, slug },
      };

      const response = await axios(config);
      const responseData = response.data.data;
      setGameData(responseData);

      if (responseData.length > 0) {
        const firstItem = responseData[0];
        setSubCategorySlug(firstItem.slug);
        setSubCategoryName(firstItem.name);
        setData(prevData => ({
          ...prevData,
          subCategory: {
            slug: firstItem.slug,
            id: firstItem._id,
            name: firstItem.name,
          }
          
        }));
      //   localStorage.setItem("sub_category_slug", firstItem.slug);
      // localStorage.setItem("sub_category_id", firstItem._id);
      // localStorage.setItem("sub_category_name", firstItem.name);
      }

      const completeImageUrl = `${response.data.urlImage}`;
      setGameImage(completeImageUrl);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };



  

  const fetchSlider = async () => {
    try {
      isLoading.current = true;
      const url = `${process.env.REACT_APP_API_URL}slider`;

      setLoading(true);
      const config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: { user_id },
      };

      const response = await axios(config);
      setSliderData(response.data.data);
      const completeImageUrl = `${response.data.urlImage}`;
      setSliderImg(completeImageUrl);
      isLoading.current = false;
      setLoading(false);
    } catch (error) {
      isLoading.current = false;
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };




  return (
  
    <div className="Homesec padding_all">
        {loading ? (
      <div className="img-fluidloder">
      <img
      src={loader}
      alt="quiz"
      
    />
    </div>
    ) : (
      <Container>
      <Slider {...sliderhome} className="pt-2">
  {/* Map through the sliderData and render each image */}
  {sliderData && sliderData.map((item, index) => (
    <div key={index}>
      <img
        src={`${sliderimg}/${item.image}`}
        className="img-fluid"
        alt={`Slide ${index + 1}`} // Provide a meaningful alt text
      />
    </div>
  ))}
</Slider>

        {/* marquee tag */}
        <div className="Marquee_Tag bg-white">
          <div className="d-flex justify-content-between align-items-center">
            <div className="h-25 w-28 sound_icon">
              <AiFillSound />
            </div>
            <div className="Notice">
              <p className="">
                Our customer service never send a link to the member,if you
                received a link from someone who pro-claimed as 91club customer
                service do not click the link to prevent being hack or lost
                data. Thank you
              </p>
            </div>

            <div className="details">
              <button className="text-slate-900	 text-white d-flex">
                <FaGripfire />
                Details
              </button>
            </div>
          </div>
        </div>
        <Row>
          <Col sm={4} xs={3}>
          {userData &&
            userData.map((game, index) => (
              <Col className="mb-2" key={index}>
                <div className="bg-red tab_grid">
                  <img src={`${images}/${game.image}`} />
                  <p>{game.name}</p>
                </div>
              </Col>
            ))}
            </Col>
            <Col sm={8} xs={9}>
          {gameData && gameData.map((games, index) => (
              <Col>
                <div className="bg-red tab_grid_right mb-2">
                {/* <Link to={`/Wingo?categorySlug=${categorySlug}&categoryName=${categoryName}&subCategorySlug=${subCategorySlug}&subCategoryName=${subCategorySlug}`} className="no-underline"> */}
                <Link to="/Wingo" onClick={handleNavigation} className="no-underline">
                    <div className="d-flex justify-content-between">
                      <div className="text-start right_tab">
                        <h2 className="mb-0">{games.name}</h2>
                        <p className="mb-0">
                          {games.description}
                          <span>Green/Red/Purple to win</span>
                        </p>
                      </div>
                      <div>
                        <img
                          src={`${gameimages}/${games.image}`}
                          alt="pool ball"
                          className="w-20"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="bg-red tab_grid_right mb-2">
                        <Link to="/gamek3" className="no-underline">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Game k3</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                        <Link to="/Game5d" className="no-underline">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Game 5d</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div>
                        <div className="bg-red tab_grid_right mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="text-start right_tab">
                                    <h2 className="mb-0">Win Go</h2>
                                    <p className="mb-0">Guess Number<span>Green/Red/Purple to win</span></p>
                                </div>
                                <div>
                                    <img src={poolball} alt="pool ball" className='w-20' />
                                </div>
                            </div>
                        </div> */}
                <Link className="d-flex justify-content-center border-orange-500 text-orange-500 align-items-center border-2 rounded-full p-2 no-underline">
                  View All <FaRegArrowAltCircleRight className="ml-2" />
                </Link>
              </Col>
            ))}
            </Col>
        </Row>
        <Wininginfo />
        <Todayearningchart />
      </Container>
    )}
    </div>
  );
}
