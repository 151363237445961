import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Container } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AiOutlineMail } from "react-icons/ai";
import { IoPhonePortraitSharp } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdOutlineHeadphones } from "react-icons/md";
import Swal from "sweetalert2";
import { EyeSlash, EyeFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export default function Login() {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [ipadress, setIpadress] = useState("");
  const [rememberPassword, setRememberPassword] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [browserId, setBrowserId] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const savedMobile = localStorage.getItem("savedMobile");
    const savedPassword = localStorage.getItem("savedPassword");
    // const savedIpadress = localStorage.getItem("savedIpadress");

    if (savedMobile && savedPassword) {
      setMobile(savedMobile);
      setPassword(savedPassword);
      setRememberPassword(true);
    }

    // if (savedIpadress) {
    //   setIpadress(savedIpadress);
    // }
  }, []);

  useEffect(() => {
    let browserIdd = Cookies.get('browserId');
    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set('browserId', browserIdd, { expires: 20 }); // Store the ID in a cookie that expires in 20 days
    }
    setBrowserId(browserIdd);
    setIpadress(browserIdd);
    localStorage.setItem("savedIpadress", browserIdd);
  }, []);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile, password, ipadress }),
      });
  
      const data = await response.json();
  
      if (data.success === "1") {
  
          // setIpadress(browserId);
          // alert(data.user.ipadress);
          await Swal.fire({
            title: "Success!",
            text: data.message,
            icon: "success",
            confirmButtonText: "OK",
            timer: 3000,
            willClose: () => {
              localStorage.setItem("user_id", data.user.user_id);
              navigate("/home");
            }
          });
      } else {
        Swal.fire({
          title: "Login Failed",
          text: data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const handleRememberPasswordChange = (e) => {
    setRememberPassword(e.target.checked);
  };

  return (
    <section className="Login_section">
      <div className="Login">
        <Container>
          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="arrow_login">
              <IoIosArrowBack className="text-white" />
            </div>
            <div className="headinglogin">
              <h5 className="text-white mb-0">Login</h5>
            </div>
            <div className="language text-white">Hindi</div>
          </div>
          <div className="text-start Login_heading text-white">
            <h1 className="">Log in</h1>
            <p className="mb-0">
              Please log in with your phone number or email
            </p>
            <p className="mb-0">
              If you forget your password, please contact customer service
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="login_method">
          <Tabs>
            <TabList>
              <Tab>
                <div className="width-tab">
                  <div className="icon_login">
                    <IoPhonePortraitSharp />
                  </div>
                  <h6 className="mb-0">Log In With Phone</h6>
                </div>
              </Tab>
              <Tab>
                <div className="width-tab">
                  <div className="icon_login">
                    <AiOutlineMail />
                  </div>
                  <h6 className="mb-0"> Email Login</h6>
                </div>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="Login_form">
                <Form>
                  <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <IoPhonePortraitSharp />
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      type="tel"
                      placeholder="Enter Phone Number"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 position-relative" controlId=" formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                      Password
                    </Form.Label>
                    <Form.Control
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="input_login_radius_custum"
                    />
                    {showPassword ? (
                      <EyeSlash
                        className="fs-3 eye"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <EyeFill
                        className="fs-3 eye"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </Form.Group>

                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Remember password"
                      checked={rememberPassword}
                      onChange={handleRememberPasswordChange}
                    />
                  </Form.Group>
                  <div onClick={handleLogin} className="login_btn">
                    Login
                  </div>
                  <div className="register_btn">
                    <Link className="register" to="/Register">
                      <button>Register</button>
                    </Link>
                  </div>
                </Form>
                <div className="support d-flex justify-content-evenly my-8">
                  <div className="forgetpsw">
                    <Link to="/ForgotPsw">
                      <div className="d-flex justify-content-center">
                        <FaLock className="icon" />
                      </div>
                    </Link>
                    <span>Forget Password</span>
                  </div>
                  <div className="forgetpsw">
                    <div className="d-flex justify-content-center">
                      <MdOutlineHeadphones className="icon" />
                    </div>
                    <span>Custumer Services</span>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="Login_form">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="d-flex align-items-center label_login">
                      <AiOutlineMail />
                      Mail
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="d-flex align-items-center label_login">
                      <FaLock />
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="input_login_radius_custum"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 text-start"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check type="checkbox" label="Remember password" />
                  </Form.Group>
                  <div className="login_btn">
                    <button>Login</button>
                  </div>

                  <div className="register_btn">
                    <Link className="register" to="/Register">
                      <button>Register</button>
                    </Link>
                  </div>
                </Form>
                <div className="support d-flex justify-content-evenly my-8">
                  <div className="forgetpsw">
                    <Link to="/Forgotpsw">
                      <div className="d-flex justify-content-center">
                        <FaLock className="icon" />
                      </div>
                    </Link>
                    <span>Forget Password</span>
                  </div>
                  <div className="forgetpsw">
                    <div className="d-flex justify-content-center">
                      <MdOutlineHeadphones className="icon" />
                    </div>
                    <span>Custumer Services</span>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    </section>
  );
}
