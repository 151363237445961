import React, { createContext, useState, useContext } from 'react';

// Create a Context
const DataContext = createContext();

// Create a Provider component
export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    userId: null,
    category: {
      slug: null,
      id: null,
      name: null,
    },
    subCategory: {
      slug: null,
      id: null,
      name: null,
    },
  });

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useData = () => useContext(DataContext);
