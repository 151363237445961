import React from 'react'
import { Container, Row, Col,Card} from 'react-bootstrap'
import Logo from '../assets/images/Logo.png'
import box from '../assets/images/box.jpg'
import { FaMedal } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa6";
import { FaRegAddressCard } from "react-icons/fa6";
import { GiTrophy } from "react-icons/gi";

export default function Activity() {
  return (
    <section className="Activity padding_all">
        <div className="bg_color_all">
          <Container>
                <div className="logoactivity w-22 mx-auto">
                    <img src={Logo} className="w-22" alt="" />
                </div>
                <div className="text-start Activity_details">
                        <h3>Activity</h3>
                        <p>Please remember to follow the event page</p>
                        <p>We will launch user feedback activities from time to time</p>
                    </div>

            </Container>  
        </div>
      <Container>
      <div className="d-flex justifyc-content-between gap-4">
        <div className="icon_new  ">
            <div className="icon_image d-flex justify-content-center">
                    <FaMedal/>
            </div>
            <h3>Activity Award</h3>
        </div>
        <div className="icon_new  ">
            <div className="icon_image d-flex justify-content-center">
                    <FaUserPlus/>
            </div>
            <h3>Invitation bonus</h3>
        </div>
        <div className="icon_new  ">
            <div className="icon_image d-flex justify-content-center">
                    <FaRegAddressCard/>
            </div>
            <h3>Betting rebate</h3>
        </div>
        
        <div className="icon_new  ">
            <div className="icon_image d-flex justify-content-center">
                    <GiTrophy/>
            </div>
            <h3>Super Jackpot</h3>
        </div>
        
</div>
<Row>
    <Col xs={6}>
        <Card className="reedem_details">
                <img src={box}/>    
            <Card.Body className="reedem">
                <h3 className="mb-0 text-start text-sm">Gift</h3>
                <p className="mb-0 text-start text-sm">Enter the redemption code to receive gift rewards</p>
            </Card.Body>
        </Card>

    </Col>
    <Col xs={6}>
        <Card className="reedem_details">
                <img src={box}/>    
            <Card.Body className="reedem">
                <h3 className="mb-0 text-start text-sm">Attendent Bonus</h3>
                <p className="mb-0 text-start text-sm">Enter the redemption code to receive gift rewards</p>
            </Card.Body>
        </Card>

    </Col>
    <Col xs={12}>
        <Card className="mt-2 banner_game">
              <div className="banner_image">
              <img src={box} />    
              </div>
            <Card.Body className="reedem">
                <h3 className="mb-0 text-start text-sm fw-bold">New Member First Deposit Bonus</h3>
            </Card.Body>
        </Card>

    </Col>
    <Col xs={12}>
        <Card className="mt-2 banner_game">
              <div className="banner_image">
              <img src={box} />    
              </div>
            <Card.Body className="reedem">
                <h3 className="mb-0 text-start text-sm fw-bold">Lottery Official Channel</h3>
            </Card.Body>
        </Card>

    </Col>
    <Col xs={12}>
       <div className="d-flex justify-content-center mt-2">
         <h3 className="text-muted text-sm mb-0">No More</h3>
       </div>

    </Col>
</Row>
      </Container>
    </section>
  )
}
