import {React,useState, useRef, useEffect} from 'react'
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Card, Container,Row,Col } from 'react-bootstrap'
import { MdCurrencyRupee } from "react-icons/md";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { GiWallet } from "react-icons/gi";
import { TbWallet } from "react-icons/tb";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GrDocumentVerified } from "react-icons/gr";
import axios from 'axios';

export default function Wallet() {
    const percentage = 66;

    const user_id = localStorage.getItem("user_id");
    const isLoading = useRef(false);
    const credit = localStorage.getItem('userCredit');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (!isLoading.current) {
          fetchGames();
        }
      }, []);
      const fetchGames = async () => {
        try {
          isLoading.current = true;
          const url = `${process.env.REACT_APP_API_URL}profile-list`;
      
          setLoading(true);
      
          const config = {
            method: "POST",
            url: url,
            headers: {
              "Content-Type": "application/json",
            },
            data: { user_id },
          };
      
          const response = await axios(config);
    
          const responseData = response.data.data;
          setUserData(responseData);
          const credit = responseData.credit;
          // alert(credit);
        localStorage.setItem('userCredit', credit);
    
          isLoading.current = false;
          setLoading(false);
        } catch (error) {
          isLoading.current = false;
          console.error("Error fetching user data:", error);
          setLoading(false);
        }
      };

    return (
        <section className="wallet padding_all">
            <div className='topheader p-2 bg-color-all d-flex justify-content-between align-items-center'>
                <Link to="/home" className='text-white text-2xl'><MdOutlineKeyboardArrowLeft /></Link>
                <h4 className="mb-0 text-white">Wallet</h4>
                <Link to="/" className='w-5'></Link>
            </div>
            <div className='bg-color-all wallet_Details text-center'>
                <div className="wallet_amount d-flex justify-content-center align-items-center">
                    <GiWallet className='text-white text-2xl' />
                </div>
                <div className="wallet_amount d-flex justify-content-center align-items-center">
                    <MdCurrencyRupee className='text-white' />
                    <h3 className='text-white mb-0'>{credit}</h3>
                </div>
                <p className='text-white'>Total Balance</p>
                <div className="d-flex justify-content-evenly align-items-center">
                    <div className="text-center">
                        <h4 className='text-white  mb-0'>{credit}</h4>
                        <p className='text-white mb-0 totalamount'>Total amount</p>
                    </div>
                    <div className="text-center">
                        <h4 className='text-white  mb-0'>0</h4>
                        <p className='text-white mb-0 totalamount'>Total Deposit amount</p>
                    </div>
                </div>
            </div>

            <Container>
                <div className="wallet_transfer mb-3">
                    <Card className="p-3">
                        <div className="d-flex justify-content-evenly">
                            <div>
                                <div className="circlegraph">
                                    <CircularProgressbar value={percentage} text={`${percentage}%`}
                                        styles={buildStyles({
                                            // Rotation of path and trail, in number of turns (0-1)
                                            rotation: 0.25,

                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: 'butt',

                                            // Text size
                                            textSize: '16px',

                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Can specify path transition in more detail, or remove it entirely
                                            // pathTransition: 'none',

                                            // Colors
                                            pathColor: `rgba(255, 255, 255, ${percentage / 100})`,
                                            textColor: '#f88',
                                            trailColor: '#ff9742 ',
                                            backgroundColor: '#ff9742 ',
                                        })} />
                                </div>

                                <div className="wallet_amount d-flex justify-content-center align-items-center">
                                    <MdCurrencyRupee className='text-dark' />
                                    <h3 className='text-dark mb-0 wallet_type'>0.00</h3>
                                </div>
                                <h6 className='text-dark mb-0 wallet_type'>Main Wallet</h6>
                            </div>
                            <div>
                                <div className="circlegraph">
                                    <CircularProgressbar value={percentage} text={`${percentage}%`}
                                        styles={buildStyles({
                                            // Rotation of path and trail, in number of turns (0-1)
                                            rotation: 0.25,

                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                            strokeLinecap: 'butt',

                                            // Text size
                                            textSize: '16px',

                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Can specify path transition in more detail, or remove it entirely
                                            // pathTransition: 'none',

                                            // Colors
                                            pathColor: `rgba(255, 255, 255, ${percentage / 100})`,
                                            textColor: '#f88',
                                            trailColor: '#ff9742 ',
                                            backgroundColor: '#ff9742 ',
                                        })} />

                                </div>
                                <div className="wallet_amount d-flex justify-content-center align-items-center">
                                    <MdCurrencyRupee className='text-dark' />
                                    <h3 className='text-dark mb-0 wallet_type'>0.00</h3>
                                </div>
                                <h6 className='text-dark mb-0 wallet_type'>3rd Party Wallet</h6>
                            </div>

                        </div>
                    <div className="main_transfer">
                        <Link to="/" className="no-underline Invitation_link">Main Wallet Transfer</Link>
                    </div>
                    <div className="d-flex justify-content-between gap-4">
                        <Link className="no-underline" to="/">
                        <div className="walleticon_de">
                            <div className="icon_all">
                                <GiWallet style={{ "--i": "#fcc999" }}/>
                            </div>
                            Deposit
                        </div>
                        </Link>
                        <Link className="no-underline" to="/Withdraw">
                        <div className="walleticon_de">
                            <div className="icon_all">
                                <TbWallet style={{ "--i": "#5ca6ff" }}/>
                            </div>
                            Withdraw
                        </div>
                        </Link>
                        <Link className="no-underline" to="/Deposithistory">

                        <div className="walleticon_de">
                            <div className="icon_all">
                                <IoDocumentTextOutline style={{ "--i": "#f95b5b" }}/>
                            </div>
                            Deposit History
                        </div>
                        </Link>
                        <Link className="no-underline" to="/Withdrawhistory.jsx">

                        <div className="walleticon_de">
                            <div className="icon_all">
                                <GrDocumentVerified style={{ "--i": "#fcc999" }}/>
                            </div>
                            Withdrawal History
                        </div>
                        </Link>
                    </div>
                    </Card>
                </div>
                {/* <Row>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>Lottery</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>TB_Chess</p>
                        </div>
                    </Col>
                    
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>Wicket 9</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>Jili</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>MG</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>JDB</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>Evo_Video</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>Card 365</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>TB</p>
                        </div>
                    </Col>
                    
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>AG Video</p>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="text-center bg-white box_custum" >
                            <h2 className="mb-0">0</h2>
                            <p>PG</p>
                        </div>
                    </Col>
                    
                </Row> */}
            </Container>

        </section>
    )
}
